import React, { Component } from "react"

import TransitionLink from "gatsby-plugin-transition-link"
// import Link from 'gatsby-plugin-transition-link'

import { TweenMax } from "gsap"

class Header extends Component {
   constructor(props) {
      super(props)
      // reference to the DOM node
      this.superTitleEl = null
      this.titleEl = null
      // reference to the animation
      this.superTitleTweenIn = null
      this.superTitleTweenOut = null

      this.titleTweenIn = null
      this.titleTweenOut = null
   }

   componentDidMount() {
      this.superTitleTweenIn = TweenMax.fromTo(
         this.superTitleEl,
         0.6,
         { y: 20, opacity: 0, paused: false },
         { y: 0, opacity: 1, paused: false }
      )

      this.titleTweenIn = TweenMax.fromTo(
         this.titleEl,
         1,
         { y: 20, opacity: 0, paused: false },
         { y: 0, opacity: 1, paused: false }
      )

      this.superTitleTweenOut = TweenMax.to(this.superTitleEl, 1, {
         y: -20,
         opacity: 0,
         paused: true,
      })

      this.titleTweenOut = TweenMax.to(this.titleEl, 1, {
         y: -20,
         opacity: 0,
         paused: true,
      })
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.animationStatus.transitionStatus === "entering") {
         this.superTitleTweenIn.play()
         this.titleTweenIn.play()
      } else if (this.props.animationStatus.transitionStatus === "exiting") {
         this.superTitleTweenOut.play()
         this.titleTweenOut.play()
      }
   }

   render() {
      let titleText = this.props.title

      return (
         <header className={this.props.pageStyles.header}>
{this.props.isProjectPage ? <TransitionLink
               to="/projects"
               exit={{ length: 0.7 }}
               entry={{ delay: 0.5 }}
            >
               <h3 className="superTitle" ref={h3 => (this.superTitleEl = h3)}>
                  {this.props.superTitle}
               </h3>
            </TransitionLink> : <TransitionLink
               to="/"
               exit={{ length: 0.7 }}
               entry={{ delay: 0.5 }}
            >
               <h3 className="superTitle" ref={h3 => (this.superTitleEl = h3)}>
                  {this.props.superTitle}
               </h3>
            </TransitionLink>}

            <h1 className="title" ref={h1 => (this.titleEl = h1)}>
               {titleText}
            </h1>

            <nav>
               <ul>
                  <li>
                     <TransitionLink
                        to="/about-me"
                        exit={{ length: 0.7 }}
                        entry={{ delay: 0.5 }}
                     >
                        About me
                     </TransitionLink>
                  </li>
                  <li>
                     <TransitionLink
                        to="/featured-work"
                        exit={{ length: 0.7 }}
                        entry={{ delay: 0.5 }}
                     >
                        Featured work
                     </TransitionLink>
                  </li>
                  <li>
                     <TransitionLink
                        to="/full-discography"
                        exit={{ length: 0.7 }}
                        entry={{ delay: 0.5 }}
                     >
                        Full discography
                     </TransitionLink>
                  </li>{" "}
                  <li>
                     <TransitionLink
                        to="/projects"
                        exit={{ length: 0.7 }}
                        entry={{ delay: 0.5 }}
                     >
                        Other Projects
                     </TransitionLink>
                  </li>
                  <li>
                     <TransitionLink
                        to="/contact"
                        exit={{ length: 0.7 }}
                        entry={{ delay: 0.5 }}
                     >
                        Contact
                     </TransitionLink>
                  </li>
               </ul>
            </nav>
         </header>
      )
   }
}

Header.defaultProps = {
   isProjectPage: false,
 }

export default Header
