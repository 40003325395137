import React from 'react'
import {Helmet} from 'react-helmet'

import {useStaticQuery, graphql } from 'gatsby'


import '../styles/reset.css'
import '../styles/index.scss'
import '../../node_modules/font-awesome/css/font-awesome.min.css';



const Head = ( { title }) => {
    
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <Helmet title={`${title} | ${data.site.siteMetadata.title}`}>
            {/* <link href="https://fonts.googleapis.com/css?family=Archivo:400,400i,600,600i|Roboto:400,400i,500,500i,700,700i&display=swap" rel="stylesheet"></link> */}
            <link href="../../node_modules/font-awesome/css/font-awesome.min.css" rel="stylesheet"></link>
        </Helmet>
    )
}

export default Head