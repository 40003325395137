import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const Footer = props => {
   const authordata = useStaticQuery(graphql`
      query {
         site {
            siteMetadata {
               author
            }
         }
      }
   `)

   return (
      <footer style={props.footerContent.styles}>
         <ul className="footerC2As">
            {props.footerContent.C2As.map((c2a, index) => {
               return (
                  <li key={index}>
                     <Link to={`/${c2a.link}`}>{c2a.text}</Link>
                  </li>
               )
            })}
         </ul>
         <p className="tinyText">
            Created by {authordata.site.siteMetadata.author}, © 2019
         </p>
      </footer>
   )
}

export default Footer
