import React from "react"
import Head from "./head"

import Header from "./header"
import Footer from "./footer"

//import TransitionLink from 'gatsby-plugin-transition-link'

if (typeof window !== "undefined") {
   // eslint-disable-next-line global-require
   require("smooth-scroll")('a[href*="#"]')
}

class Layout extends React.Component {
   render() {
      return (
         <>
            <Head title={this.props.title} />
            <div className={`${this.props.pageStyles.content} content`}>
               <Header
                  superTitle={this.props.superTitle}
                  title={this.props.title}
                  pageStyles={this.props.pageStyles}
                  animationStatus={this.props.animationStatus}
                  isProjectPage={this.props.isProjectPage}
               />
               <main>{this.props.children}</main>
            </div>
            <Footer footerContent={this.props.footerContent} />
         </>
      )
   }
}

export default Layout
